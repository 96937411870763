<template>
  <div :id="id1" style="width:100%;height:100%">
    <div :id="id2"  style="width:100%;height:100%"></div>
     <notData v-if="propData.length == 0" />
  </div>
</template>
<script>
export default {
  props: {
    propData: {
      type: Array,
      default: () => []
    },
    id1: {
      type: String,
      default: ''
    },
    id2: {
      type: String,
      default: ''
    },
    // 是否堆叠
    stack: {
      type: String,
      default: ''
    },
    // 柱子颜色
    barColor: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      data: []
    }
  },
  watch: {
    propData: {
      handler (n) {
        this.data = n
        this.init()
      },
      deep: true
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeCharts, true)
    this.$erd(this.id1, this.id2)
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeCharts, true)
  },
  methods: {
    resizeCharts () {
      const myChart = this.$echarts.init(
        document.getElementById(this.id2)
      )
      myChart.resize()
    },
    handleSeries () {
      this.series = this.data.map(item => {
        return {
          name: item.name,
          type: 'bar',
          barMaxWidth: '60',
          stack: this.stack,
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#000'
            },
            formatter: (params) => {
              if (this.id1 === 'rent_box') {
                const str1 = +params.value ? `数量:${+params.value}` : ''
                const str2 = item.price[params.dataIndex] ? `价值:${item.price[params.dataIndex]}` : ''
                return `${str1}\n${str2}`
              }
              return +params.value ? params.value : ''
            }
          },
          data: item.value
        }
      })
    },
    init () {
      const myChart = this.$echarts.init(
        document.getElementById(this.id2)
      )
      // myChart.clear() // 清除数据缓存
      this.handleSeries()
      console.log(this.data)
      const option = {
        legend: {
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '1%',
          containLabel: true
        },
        // color: [
        //   '#5470c6',
        //   '#91cc75',
        //   '#fac858',
        //   '#73c0de'
        // ],
        color: this.barColor,
        xAxis: [
          {
            type: 'category',
            data: this.data[0] && this.data[0].dataX,
            axisLabel: {
              color: '#000',
              fontSize: 12,
              margin: 10,
              rotate: this.id1 === 'fault_cycle_box' ? 30 : 0
            },
            axisTick: {
              // 坐标轴刻度相关设置。
              show: false
            },
            axisLine: {
              // 坐标轴轴线相关设置
              lineStyle: {
                type: 'dashed',
                color: '#ccc'
              }
            },
            splitLine: {
              // 坐标轴在 grid 区域中的分隔线。
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 5,
            axisLabel: {
              formatter: function (value) {
                return value
              },
              color: '#000',
              margin: 10
            },
            axisLine: {
              lineStyle: {
                type: 'dashed',
                color: '#ccc'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#ccc'
              }
            }
          }
        ],
        series: this.series
      }
      myChart.setOption(option, true)
    }
  }
}
</script>
