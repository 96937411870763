<template>
  <div class="layout_common all_aging">
    <div class="main_box">
      <div class="main_box_con">
          <el-date-picker
            v-model="day"
            type="week"
            format="yyyy 第 WW 周"
            size="small"
            :picker-options="{ firstDayOfWeek: 1 }"
            placeholder="选择周"
            @change="dayChange"
            :clearable="false"
          >
          </el-date-picker>
        <div>
        </div>
        <div class="echart" v-loading="loading">
          <barEchart id1="allAging_box" id2="allAging_echart" :propData="echartData" :barColor="['#91cc75','#5470c6','#fac858']" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import barEchart from '../components/agingBar'
import { allAging } from '@/api'
import { formatDate } from '@/utils/common'
export default {
  components: {
    barEchart
  },
  data () {
    return {
      loading: false,
      day: new Date(),
      echartData: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      allAging({ time: formatDate(this.day) }).then(res => {
        this.loading = false
        this.handleEchartData(res.data)
      })
    },
    // 根据后台返回的数据进行处理成图表的格式的数据
    handleEchartData (data) {
      const dataX = data.map(item => item.createTime)
      const responseOne = data.map(item => item.rescription.responseOne)
      const responseTwo = data.map(item => item.rescription.responseTwo)
      const handleThree = data.map(item => item.rescription.handleThree)
      const arr = [
        { name: '< 24小时', value: responseOne, dataX },
        { name: '24-72 小时', value: responseTwo, dataX },
        { name: '> 72小时', value: handleThree, dataX }
      ]
      this.echartData = arr
    },
    dayChange (val) {
      this.day = val
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.all_aging {
    margin-bottom: 20px;
  .echart {
    height: calc(100% - 80px);
  }
}
</style>
